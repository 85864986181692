



















import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'Page',
  props: {
    blok: {
      type: Object,
      required: true,
    },
    isFetching: {
      type: Boolean,
      required: false,
      default: false,
    },
    page: {
      type: String,
      required: false,
      default: 'page',
    },
  },
});
